import React from 'react';
//import { Link } from '~/components/Link';
import appStoreBadge from './assets/app-store-badge.svg';
import googlePlayBadge from './assets/google-play-badge.svg';
import logo from './assets/new-ramdam-logo.svg';
import qrCode from './assets/qr-code-ramdam-app.svg';

import './App.css';

function App() {
  return (
      <main className="flex min-h-screen flex-col items-center bg-gray-50 px-4 py-6">
        <header className="mb-6">
          <a href="https://ramd.am">
            <img src={logo} alt="Ramdam" className="h-8"/>
          </a>
        </header>

        <section className=" w-full rounded-xl border border-coldgray-200 bg-white p-8 shadow-sm sm:w-[600px]">
          <p className="mb-4 text-center font-body text-l-regular leading-6 text-gray-800">
            Download our app and be part of our
            <br/>
            creator community
          </p>
          <div className="flex flex-col items-center justify-center sm:flex-row sm:space-x-4">
            <img src={qrCode} alt="QR Code" className="mb-3 h-32 w-32 sm:mb-0"/>
            <div className="flex flex-col justify-center space-y-2">
              <a
                  href="https://play.google.com/store/apps/details?id=app.ramd.am&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src={googlePlayBadge} alt="Get it on Google Play" className="h-12"/>
              </a>
              <a
                  href="https://apps.apple.com/us/app/ramdam-promote-apps-get-paid/id6444769579?itsct=apps_box_badge&itscg=30200"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src={appStoreBadge} alt="Download on the App Store" className="h-12"/>
              </a>
            </div>
          </div>
        </section>

        <footer className="mt-8 text-center text-sm text-coldgray-500">
          <span className="text-gray-800">© Ramdam</span>
          <span className="mx-2 text-gray-200">•</span>
          <a
              href="https://www.ramd.am/about"
              className="hover:underline"
              target="_blank"
              rel="noreferrer"
          >
            <span className="text-gray-800">Contact</span>
          </a>
          <span className="mx-2 text-gray-200">•</span>
          <a
              href="https://www.ramd.am/privacy-policy"
              className="hover:underline"
              target="_blank"
              rel="noreferrer"
          >
            <span className="text-gray-800">Privacy Policy</span>
          </a>
          <span className="mx-2 text-gray-200">•</span>
          <a
              href="https://www.ramd.am/terms-of-services"
              className="hover:underline"
              target="_blank"
              rel="noreferrer"
          >
            <span className="text-gray-800">T&Cs</span>
          </a>
        </footer>
      </main>
  );
}

export default App;
